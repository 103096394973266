// facetedsearch.js 

function facetserach() {
    $.extend(this, {

        /**
         * Please note that when passing in custom templates for 
         * listItemTemplate and orderByTemplate to keep the classes as
         * they are used in the code at other locations as well.
         */

        defaults: {
            items: [{
                a: 2,
                b: 1,
                c: 2
            }, {
                a: 2,
                b: 2,
                c: 1
            }, {
                a: 1,
                b: 1,
                c: 1
            }, {
                a: 3,
                b: 3,
                c: 1
            }],
            facets: {
                'a': 'Title A',
                'b': 'Title B',
                'c': 'Title C'
            },
            resultSelector: '#results',
            facetSelector: '#facets',
            facetContainer: '<div class=facetsearch id=<%= id %> ></div>',
            facetTitleTemplate: '<h3 class=facettitle><%= title %></h3>',
            facetListContainer: '<div class=facetlist></div>',
            listItemTemplate: '<div class=facetitem id="<%= id %>"><%= name %> <span class=facetitemcount>(<%= count %>)</span></div>',
            adjustableItemTemplate: '<div class=facetitem id="<%= id %>"><%= name %> <span class=facetitemcount>(<%= count %>)</span></div>',
            bottomContainer: '<div class=bottomline></div>',
            orderByTemplate: '<div class=orderby><span class="orderby-title">Sort by: </span><ul><% _.each(options, function(value, key) { %>' +
                '<li class=orderbyitem id=orderby_<%= key %>>' +
                '<%= value %> </li> <% }); %></ul></div>',
            countTemplate: '<div class=facettotalcount><%= count %> Results</div>',
            deselectTemplate: '<div class=deselectstartover>Deselect all filters</div>',
            resultTemplate: '<div class=facetresultbox><%= name %></div>',
            noResults: '<div class=results>Sorry, but no items match these criteria</div>',
            orderByOptions: {
                'a': 'by A',
                'b': 'by B',
                'RANDOM': 'by random'
            },
            state: {
                orderBy: false,
                filters: {}
            },
            showMoreTemplate: '<a id=showmorebutton>Show more</a>',
            enablePagination: true,
            paginationCount: 20,
            beforeClick: function(elem, event) {
                return true;
            } // callback function before element will be selected/deselected
        },

        /**
         * This is the first function / variable that gets exported into the 
         * jQuery namespace. Pass in your own settings (see above) to initialize
         * the faceted search
         */
        settings: {},
        facetelize: function(usersettings) {
            var self = this;
            $.extend(self.settings, self.defaults, usersettings);
            self.settings.currentResults = [];
            self.settings.facetStore = {};
            $(self.settings.facetSelector).data("settings", self.settings);
            self.initFacetCount();
            self.filter();
            self.order();
            self.createFacetUI();
            self.updateResults();
        },

        /**
         * This is the second function / variable that gets exported into the 
         * jQuery namespace. Use it to update everything if you messed with
         * the settings object
         */
        facetUpdate: function() {
            var self = this;
            self.filter();
            self.order();
            self.updateFacetUI();
            self.updateResults();
        },

        /**
         * The following section contains the logic of the faceted search
         */

        /**
         * initializes all facets and their individual filters 
         */
        initFacetCount: function() {
            var self = this;
            _.each(self.settings.facets, function(facettitle, facet) {
                self.settings.facetStore[facet] = {};
            });
            _.each(self.settings.items, function(item) {
                // intialize the count to be zero
                _.each(self.settings.facets, function(facettitle, facet) {
                    if ($.isArray(item[facet])) {
                        _.each(item[facet], function(facetitem) {
                            self.settings.facetStore[facet][facetitem] = self.settings.facetStore[facet][facetitem] || {
                                count: 0,
                                id: _.uniqueId("facet_"),
                                image: item.Image ? item.Image : null,
                            }
                        });
                    } else {
                        if (item[facet] !== undefined) {
                            self.settings.facetStore[facet][item[facet]] = self.settings.facetStore[facet][item[facet]] || {
                                count: 0,
                                id: _.uniqueId("facet_"),
                                image: item.Image ? item.Image : null,
                            }
                        }
                    }
                });
            });
            // sort it:
            _.each(self.settings.facetStore, function(facet, facettitle) {
                var sorted = _.keys(self.settings.facetStore[facettitle]).sort();
                if (self.settings.facetSortOption && self.settings.facetSortOption[facettitle]) {
                    sorted = _.union(self.settings.facetSortOption[facettitle], sorted);
                }
                var sortedstore = {};
                _.each(sorted, function(el) {
                    sortedstore[el] = self.settings.facetStore[facettitle][el];
                });
                self.settings.facetStore[facettitle] = sortedstore;
            });
        },

        /**
         * resets the facet count
         */
        resetFacetCount: function() {
            var self = this;
            _.each(self.settings.facetStore, function(items, facetname) {
                _.each(items, function(value, itemname) {
                    self.settings.facetStore[facetname][itemname].count = 0;
                });
            });
        },

        /**
         * Filters all items from the settings according to the currently 
         * set filters and stores the results in the self.settings.currentResults.
         * The number of items in each filter from each facet is also updated
         */
        filter: function() {
            var self = this;
            // first apply the filters to the items
            self.settings.currentResults = _.select(self.settings.items, function(item) {
                var filtersApply = true;
                _.each(self.settings.state.filters, function(filter, facet) {
                    if ($.isArray(item[facet])) {
                        var inters = _.intersect(item[facet], filter);
                        if (inters.length == 0) {
                            filtersApply = false;
                        }
                    } else {
                        if (filter.length && _.indexOf(filter, item[facet]) == -1) {
                            filtersApply = false;
                        }
                    }
                });
                return filtersApply;
            });
            // Update the count for each facet and item:
            // intialize the count to be zero
            self.resetFacetCount();
            // then reduce the items to get the current count for each facet
            _.each(self.settings.facets, function(facettitle, facet) {
                _.each(self.settings.currentResults, function(item) {
                    if ($.isArray(item[facet])) {
                        _.each(item[facet], function(facetitem) {
                            self.settings.facetStore[facet][facetitem].count += 1;
                        });
                    } else {
                        if (item[facet] !== undefined) {
                            self.settings.facetStore[facet][item[facet]].count += 1;
                        }
                    }
                });
            });
            // remove confusing 0 from facets where a filter has been set
            _.each(self.settings.state.filters, function(filters, facettitle) {
                _.each(self.settings.facetStore[facettitle], function(facet) {
                    if (facet.count == 0 && self.settings.state.filters[facettitle].length) facet.count = "+";
                });
            });
            self.settings.state.shownResults = 0;
        },

        /**
         * Orders the currentResults according to the self.settings.state.orderBy variable
         */
        order: function() {
            var self = this;
            if (self.settings.state.orderBy) {
                $(".activeorderby").removeClass("activeorderby");
                $('#orderby_' + self.settings.state.orderBy).addClass("activeorderby");
                self.settings.currentResults = _.sortBy(self.settings.currentResults, function(item) {
                    if (self.settings.state.orderBy == 'RANDOM') {
                        return Math.random() * 10000;
                    } else {
                        return item[self.settings.state.orderBy];
                    }
                });
            }
        },

        /**
         * The given facetname and filtername are activated or deactivated
         * depending on what they were beforehand. This causes the items to
         * be filtered again and the UI is updated accordingly.
         */
        toggleFilter: function(key, value) {
            var self = this;
            self.settings.state.filters[key] = self.settings.state.filters[key] || [];
            if (_.indexOf(self.settings.state.filters[key], value) == -1) {
                self.settings.state.filters[key].push(value);
            } else {
                self.settings.state.filters[key] = _.without(self.settings.state.filters[key], value);
                if (self.settings.state.filters[key].length == 0) {
                    delete self.settings.state.filters[key];
                }
            }
            self.filter();
        },

        /**
         * The following section contains the presentation of the faceted search
         */

        /**
         * This function is only called once, it creates the facets ui.
         */
        createFacetUI: function() {
            var self = this;
            var itemtemplate = _.template(self.settings.listItemTemplate);
            var titletemplate = _.template(self.settings.facetTitleTemplate);
            var containertemplate = _.template(self.settings.facetContainer);
            var adjustableItemTemplate = _.template(self.settings.adjustableItemTemplate);

            $(self.settings.facetSelector).html("");
            _.each(self.settings.facets, function(facettitle, facet) {
                var facetHtml = $(containertemplate({
                    id: facet
                }));
                var facetItem = {
                    title: facettitle
                };
                var facetItemHtml = $(titletemplate(facetItem));

                facetHtml.append(facetItemHtml);
                var facetlist = $(self.settings.facetListContainer);
                _.each(self.settings.facetStore[facet], function(filter, filtername) {
                    var image = filter.image ? "style=\" background: url(/PDB/Ressource/web/viva/pictos/" + filter.image + ")\"" : "";
                    var item = {
                        id: filter.id,
                        name: filtername,
                        count: filter.count,
                        image: image
                    };

                    var filteritem = $(itemtemplate(item));
                    if (_.indexOf(self.settings.state.filters[facet], filtername) >= 0) {
                        filteritem.addClass("activefacet");
                    }
                    facetlist.append(filteritem);
                });

                if (facet == "Attr2") {
                    var item = {
                        id: "adjustable"
                    };
                    var filteritem = $(adjustableItemTemplate(item));
                    facetlist.append(filteritem);
                }


                facetHtml.append(facetlist);
                $(self.settings.facetSelector).append(facetHtml);
            });
            // add the click event handler to each facet item:
            $('.facetitem', $(self.settings.facetSelector)).click(function(event) {
                if (typeof self.settings.beforeClick == 'function') {
                    var ret = self.settings.beforeClick.apply(this, [event]);
                    if (!ret)
                        return false;
                }


                var filter = self.getFilterById(this.id);
                self.toggleFilter(filter.facetname, filter.filtername);
                $(self.settings.facetSelector).trigger("facetedsearchfacetclick", filter);
                self.order();
                self.updateFacetUI();
                self.updateResults();
            });
            // Append total result count
            var bottom = $(self.settings.bottomContainer);
            countHtml = _.template(self.settings.countTemplate, {
                count: self.settings.currentResults.length
            });
            $(bottom).append(countHtml);
            // generate the "order by" options:
            var ordertemplate = _.template(self.settings.orderByTemplate);
            var itemHtml = $(ordertemplate({
                'options': self.settings.orderByOptions
            }));
            $(bottom).append(itemHtml);
            $(self.settings.facetSelector).append(bottom);
            $('.orderbyitem').each(function() {
                var id = this.id.substr(8);
                if (self.settings.state.orderBy == id) {
                    $(this).addClass("activeorderby");
                }
            });
            // add the click event handler to each "order by" item:
            $('.orderbyitem').click(function(event) {
                var id = this.id.substr(8);
                self.settings.state.orderBy = id;
                $(self.settings.facetSelector).trigger("facetedsearchorderby", id);
                self.settings.state.shownResults = 0;
                self.order();
                self.updateResults();
            });
            // Append deselect filters button
            var deselect = $(self.settings.deselectTemplate).click(function(event) {
                self.settings.state.filters = {};
                jQuery.facetUpdate();
            });
            $(bottom).append(deselect);
            $(self.settings.facetSelector).trigger("facetuicreated");
        },

        /**
         * get a facetname and filtername by the unique id that is created in the beginning
         */
        getFilterById: function(id) {
            var self = this;
            var result = false;
            _.each(self.settings.facetStore, function(facet, facetname) {
                _.each(facet, function(filter, filtername) {
                    if (filter.id == id) {
                        result = {
                            'facetname': facetname,
                            'filtername': filtername
                        };
                    }
                });
            });
            return result;
        },

        /**
         * This function is only called whenever a filter has been added or removed
         * It adds a class to the active filters and shows the correct number for each
         */
        updateFacetUI: function() {
            var self = this;
            var itemtemplate = _.template(self.settings.listItemTemplate);
            _.each(self.settings.facetStore, function(facet, facetname) {
                _.each(facet, function(filter, filtername) {
                    var imageString = filter.image ? "style=\"background-image: url(/PDB/Ressource/web/viva/pictos/" + filter.image + ")\"" : "";
                    var item = {
                        id: filter.id,
                        name: filtername,
                        count: filter.count,
                        image: imageString
                    };
                    var filteritem = $(itemtemplate(item)).html();
                    $("#" + filter.id).html(filteritem);
                    if (self.settings.state.filters[facetname] && _.indexOf(self.settings.state.filters[facetname], filtername) >= 0) {
                        $("#" + filter.id).addClass("activefacet");
                    } else {
                        $("#" + filter.id).removeClass("activefacet");
                    }
                });
            });
            countHtml = _.template(self.settings.countTemplate, {
                count: self.settings.currentResults.length
            });
            $('.facettotalcount', $(self.settings.facetSelector)).replaceWith(countHtml);
        },

        /**
         * Updates the the list of results according to the filters that have been set
         */
        updateResults: function() {
            var self = this;
            $(self.settings.resultSelector).html(self.settings.currentResults.length == 0 ? self.settings.noResults : "");
            self.showMoreResults();
        },

        moreButton: null,

        showMoreResults: function() {
            var self = this;
            var showNowCount =
                self.settings.enablePagination ?
                Math.min(self.settings.currentResults.length - self.settings.state.shownResults, self.settings.paginationCount) :
                self.settings.currentResults.length;
            var itemHtml = "";
            var template = _.template(self.settings.resultTemplate);
            for (var i = self.settings.state.shownResults; i < self.settings.state.shownResults + showNowCount; i++) {
                var item = $.extend(self.settings.currentResults[i], {
                    totalItemNr: i,
                    batchItemNr: i - self.settings.state.shownResults,
                    batchItemCount: showNowCount
                });
                var itemHtml = itemHtml + template(item);
            }
            $(self.settings.resultSelector).append(itemHtml);
            if (!self.moreButton) {
                self.moreButton = $(self.settings.showMoreTemplate).click(self.showMoreResults);
                $(self.settings.resultSelector).after(self.moreButton);
            }
            if (self.settings.state.shownResults == 0) {
                self.moreButton.show();
            }
            self.settings.state.shownResults += showNowCount;
            if (self.settings.state.shownResults == self.settings.currentResults.length) {
                $(self.moreButton).hide();
            }
            $(self.settings.resultSelector).trigger("facetedsearchresultupdate");
        }

    });
}
